import React from "react"
import styled from "styled-components"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Button from "../components/Button"
import { useQuoteAssets } from "../hooks/quote"
import { useThanksAssets } from "../hooks/thanks"

const Accent = styled.span`
  color: #6c9fff;
`
const Container = styled.div`
  max-width: 510px;
  margin: 0 auto;
  padding: 0 15px 100px 15px;
`
const Content = styled.p`
  margin-bottom: 1rem;
`
const Video = styled.video`
  height: 100%;
  object-fit: cover;
  width: 100%;
  border: 2px solid #fff;
  margin-bottom: 1rem;
`

const Koszonjuk = () => {
  const { bg } = useQuoteAssets()
  const { cover, video } = useThanksAssets()

  return (
    <Layout heroOptions={{ title: <Accent>Köszönjük</Accent>, bg }}>
      <SEO title="Köszönjük a megkeresést!" slug="/koszonjuk" />
      <Container>
        <Content>Köszönjük az üzeneted!</Content>
        <h2 style={{ marginBottom: "1rem" }}>TERVEZETT LEÁLLÁS!</h2>
        <Content>
          Október 4-13-ig külföldön -nyílt tengeren- való tartózkodás miatt, sem
          leveleinkre, sem telefonhívásokra nem tudunk reagálni. Válaszadásig
          szíves türelmét kérjük!
        </Content>
        <Content>
          Üdvözlettel, <br /> FLIP UNIT csapata
        </Content>
        <Video
          key={video}
          autoPlay
          muted
          loop
          playsInline
          poster={cover}
          preload="metadata"
        >
          <source src={video} type="video/mp4" />
        </Video>
        <Button to="/">Vissza a főoldalra</Button>
      </Container>
    </Layout>
  )
}

export default Koszonjuk
